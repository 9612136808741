// import React, { useState, useRef } from 'react';
// import ReactToPrint from 'react-to-print';
// import './App.css';

// const App = () => {
//   const [imagePath, setImagePath] = useState('');
//   const [imageFile, setImageFile] = useState(null);
//   const [errorMessage, setErrorMessage] = useState('');
//   const imageRef = useRef();

//   const handleInputChange = (e) => {
//     setImagePath(e.target.value);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const filePath = `/images/${imagePath}.png`; // Adjust path according to your project structure
    
//     try {
//       const response = await fetch(filePath);
//       if (!response.ok) {
//         throw new Error('Image not found');
//       }
//       setImageFile(filePath);
//       setErrorMessage('');
//     } catch (error) {
//       setImageFile(null);
//       setErrorMessage('Please Contact College Department');
//     }
//   };

//   return (
//     <div className="App">
//       <form onSubmit={handleSubmit}>
//         <input
//           type="text"
//           value={imagePath}
//           onChange={handleInputChange}
//           placeholder="University Roll Number Ex- 20xxxxxxxx"
//         />
//         <button type="submit">Submit</button>
//       </form>
//       {errorMessage && <div className="error-message">{errorMessage}</div>}
//       {imageFile && (
//         <div>
//           <div ref={imageRef} className="image-container">
//             <img src={imageFile} alt="Please Contact College Department" />
//           </div>
//           <ReactToPrint
//             trigger={() => <button>Print</button>}
//             content={() => imageRef.current}
//             pageStyle="@page { size: auto; margin: 0mm; } @media print { img { max-width: 100%; height: 100%; } }"
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default App;
import React, { useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import './App.css';

const App = () => {
  const [imagePath, setImagePath] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [course, setCourse] = useState('BALLB');
  const [errorMessage, setErrorMessage] = useState('');
  const imageRef = useRef();

  const handleInputChange = (e) => {
    setImagePath(e.target.value);
  };

  const handleCourseChange = (e) => {
    setCourse(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const filePath = `/images/${course}/${imagePath}.png`; // Adjust path according to your project structure
    
    try {
      const response = await fetch(filePath);
      if (!response.ok) {
        throw new Error('Image not found');
      }
      setImageFile(filePath);
      setErrorMessage('');
    } catch (error) {
      setImageFile(null);
      setErrorMessage('Please Contact College Department');
    }
  };

  return (
    <div className="App">
       <div className="notice">
        <strong style={{ color: 'red' }}>
          आवश्यक सूचना<br />
सभी छात्र/छात्राओं को सूचित किया जाता है की उनके प्रवेश पत्र पर किसी  विषय से सम्बन्धित किसी भी प्रकार की कोई त्रुटि है <br/>
तो वे दिनांक 14/07/2024 को कंप्यूटर लैब में ठीक करा लें उसके बाद प्रवेश पत्र पर संशोधन नही हो सकेगा।
        </strong>
      </div>
      Please Select Course
      <form onSubmit={handleSubmit}>
        <select value={course} onChange={handleCourseChange}>
        <option value="BALLB">BA_LLB</option>
        <option value="LLB">LLB</option>
        <option value="BED">B.ED</option>
       
       
        {/* <option value="Re_BALLB_I">Re_BALLB_I</option>
        <option value="Re_BALLB_II">Re_BALLB_II</option>
        <option value="Re_BALLB_III">Re_BALLB_III</option>
        <option value="Re_BALLB_IV">Re_BALLB_IV</option>
        <option value="Re_BALLB_V">Re_BALLB_V</option>

        <option value="Re_LLB_I">Re_LLB_I</option>
        <option value="Re_LLB_II">Re_LLB_II</option>
        <option value="Re_LLB_III">Re_LLB_III</option>


        <option value="Re_LLB_I">Re_LLB_I</option> */}

          {/* <option value="BA">BA</option>
          <option value="BCOM">BCOM</option>
          <option value="BSC">BSC</option>
          <option value="MA">MA</option>
          <option value="MSC">MSC</option>
          <option value="MCOM">MCOM</option>

          <option value="ReBA_II">Re_BA_II</option>
          <option value="ReBCOM_II">Re_BCOM_II</option>
          <option value="ReBSC_II">Re_BSC_II</option>

          <option value="ReBA_IV">Re_BA_IV</option>
          <option value="ReBCOM_IV">Re_BCOM_IV</option>
          <option value="ReBSC_IV">Re_BSC_IV</option>

          <option value="ReMA_II">Re_MA_II</option>
          <option value="ReMSC_II">Re_MSC_II</option>

          <option value="ReMA_IV">Re_MA_IV</option>
          <option value="ReMSC_IV">Re_MSC_IV</option> */}
          
        </select>
        <input
          type="text"
          value={imagePath}
          onChange={handleInputChange}
          placeholder="University Roll Number Ex- 20xxxxxxxx"
        />
        <button type="submit">Submit</button>
      </form>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {imageFile && (
        <div>
          <div ref={imageRef} className="image-container">
            <img src={imageFile} alt="Please Contact College Department" />
          </div>
          <ReactToPrint
            trigger={() => <button>Print</button>}
            content={() => imageRef.current}
            pageStyle="@page { size: auto; margin: 0mm; } @media print { img { max-width: 100%; height: 100%; } }"
          />
        </div>
      )}
    </div>
  );
};

export default App;
